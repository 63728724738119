export class Notifications{
    constructor(type,msg) {
        this.type = type
        this.msg = msg
        this.wrapper = $('#notifications')
        this.show()
    }
   show(){
        this.notification = $('<li>').text(this.msg)
       this.notification.addClass(`alert alert-${this.type}`)
       this.wrapper.html(this.notification)

   }

}