import "select2/dist/css/select2.min.css"

import "../style/theme.scss"

import "select2/dist/js/select2.full.min"

import Swiper from "swiper"

import "swiper/css/swiper.min.css"
import { generateShoppingCart } from "./shoppingcart"
import "bootstrap"
import "./shoppingcart"
import "./slider"
import "./navbar"
import "./NumberCounter"
import "./shopping-cart"
import "./valid"
import "./custom-intput-quantity"
import { FiltersManager } from "./filters"
import Select from "./select"
import { Notifications } from "./notifications"
import "./header"
import "./userAction"


// import "./default_theme"

// $('.icon__close').on('click',()=>{
//     console.log("tt")
// })
// $('body').on('click','',e=>{
//     console.log('click')
// })
// $('span[data-cart-action="remove-item"]').on('click',e=>{
//     console.log('testy')
// })

//remove from cart
$("body").on("click", '[data-cart-action="remove-item"]', function () {
  const $this = $(this)

  $.ajax({
    url: prestashop.urls.pages.cart,
    type: "POST",
    data: {
      token: prestashop.static_token,
      id_product: $this.data().idProduct,
      delete: 1,
      qty: $this.data().qty,
      action: "update",
      ajax: true,
      id_customization: 0,
    },
    success: function (response) {
      response = JSON.parse(response)
      if (!response.hasError) {
        prestashop.emit("updateCart", {
          reason: {},
          resp: response,
        })
        prestashop.cart = response.cart

      }
    },
    error: () => {
      console.log("error")
    },
  })
})

//add to cart
$("body").on("click", '[data-cart-action="add-one"]', function () {
  $.ajax({
    url: prestashop.urls.pages.cart,
    type: "POST",
    data: {
      token: prestashop.static_token,
      id_product: $(this).data().productId,
      add: 1,
      qty: 1,
      action: "update",
      ajax: false,
      id_customization: 0,
    },
    success: function (response) {
      response = JSON.parse(response)
      if (!response.hasError) {
        $(".shopping-cart-box").hide("fast")

        prestashop.cart = response.cart

        generateShoppingCart()

        console.log("done add")
      }
      // prestashop.emit('updateCart', {
      //     reason: {}, resp: response
      // })
    },
  })
})
//ADD TO CART IN PRODUCT PAGE
$("body").on("click", '[data-button-action="add-to-cart"]', function () {
  console.log("click")
  const not = new Notifications(
    "warning",
    "Przedmiotów może nie być na magazynie"
  )

  $.ajax({
    url: prestashop.urls.pages.cart,
    type: "POST",
    data: {
      token: prestashop.static_token,
      id_product: $(this).data().productId,
      add: 1,
      qty: $('input[data-value="qty-for-product"]').val(),
      action: "update",
      ajax: false,
      id_customization: 0,
    },
    success: function (response) {
      response = JSON.parse(response)
      if (!response.hasError) {
        $(".shopping-cart-box").hide("fast")

        prestashop.cart = response.cart
        location.reload()
        generateShoppingCart()


      }
      // prestashop.emit('updateCart', {
      //     reason: {}, resp: response
      // })
    },
  })
})
$('#statue_checkbox').change(function(e){
  // if(this.checked){
  //   $('button[id^="pay-with-payment"]').prop('disabled',false)
  // }else{
  //   $('button[id^="pay-with-payment"]').prop('disabled',true)
  //
  // }

  $('button[id^="pay-with-payment"]').prop('disabled',!this.checked)
})



$("body").on("click", '[data-cart-action="add-promocode"]', () => {
  $.ajax({
    url: prestashop.urls.pages.cart,
    type: "POST",
    data: {
      token: prestashop.static_token,
      addDiscount: 1,
      discount_name: $('.promo-input').val(),
      ajax: 1,
      action: "update",

    },
    success: function (response) {
      // console.log(response)
      window.location.assign('./koszyk')
    },
  })
})

// $("body").on("click", '[data-link-action="remove-voucher"]', function() {
// console.log($(this).data())
//   $.ajax({
//     url: $(this).data().link,
//     type: "POST",
//     data: {
//       token: prestashop.static_token,
//       deleteDiscount: 1,
//     },
//     success: function (response) {
//       // window.location.assign("/koszyk")
//     },
//   })
// })



$(document).ready(() => {

  if(prestashop.page.page_name ==='module-cg_checkout-checkout' || prestashop.page.page_name ==='zamowienie'){
    const  $statueCheckbox = $('#statue_checkbox')
    let form = $('button[id^="pay-with-payment"]')
    if(form){
      form.prop('disabled',true)
    }

  }

  $("#wrapper").css("padding-top", $(".main-header").outerHeight())

  const filterManager = new FiltersManager()
  const manufactureSelect = new Select($(".js-manufacture-multiple"), {
    placeholder: "Producent",
    width:"100%",
    containerCssClass: "select2-custom",
    minimumResultsForSearch: -1,
    closeOnSelect: false,
  })
  manufactureSelect.init()

  const itemsPerPageSelect = new Select($(".js-itemperpage"), {
    placeholder: "30",
    width: "resolve",
    containerCssClass: "select2-custom-mini",
    closeOnSelect: true,
    minimumResultsForSearch: -1,

  })

  itemsPerPageSelect.init()
  $(".js-manufacture-multiple").on("select2:close", (e) => {
    console.log("test")
    // filterManager.setChangeForSelect()
  })
  const sortingSelect = new Select($(".js-sorting"), {
    placeholder: "",
    width: "resolve",
    containerCssClass: "select2-custom",
    closeOnSelect: true,
    minimumResultsForSearch: -1,

  })
  sortingSelect.init()

  generateShoppingCart()

  var galleryThumbsSwiper = new Swiper(".gallery-thumbs", {
    spaceBetween: 10,
    slidesPerView: 3,
    freeMode: true,
    watchSlidesVisibility: true,
    watchSlidesProgress: true,
  })
  var galleryTopSwiper = new Swiper(".gallery-top", {
    spaceBetween: 10,
    navigation: {
      nextEl: ".swiper-button-product-next",
      prevEl: ".swiper-button-product-prev",
    },
    thumbs: {
      swiper: galleryThumbsSwiper,
    },
    on: {
      click: function () {},
    },
  })
  var relatedProductsSwiper = new Swiper(".swiper-related-items", {
    slidesPerView: "auto",
    loop: true,
    autoplay: {
      delay: 3000,
    },
    // autoHeight:true,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev ",
    },
    // breakpoints: {
    //     // when window width is >= 320px
    //     320: {
    //         slidesPerView: 1,
    //     },
    //     // when window width is >= 480px
    //     480: {
    //         slidesPerView: 2,
    //     },
    //     // when window width is >= 640px
    //     1220: {
    //         slidesPerView: 3,
    //     }
    // }
  })

  $("#modalGallery").on("show.bs.modal",function(e){
    $(this).unbind(e)
    if(window.innerWidth < 768){
      return e.preventDefault()
    }else{
      setTimeout(() => {
        var galleryThumbsModal = new Swiper(".gallery-modal-thumbs", {
          spaceBetween: 10,
          slidesPerView: 4,
          freeMode: true,
          watchSlidesVisibility: true,
          watchSlidesProgress: true,
        })

        var swiperModal = new Swiper(".swiper-gallery-modal", {
          pagination: ".swiper-pagination",
          navigation: {
            prevEl: ".swiper-button-prev",
            nextEl: ".swiper-button-next",
          },
          slidesPerView: 1,
          loop: true,
          thumbs: {
            swiper: galleryThumbsModal,
          },
        })
      }, 500)
    }

  })
  $('.letter').each(function( index ) {
    const str = $(this).text().trim()
    // $(this[index]).trim()
    // $(this).text(str.charAt(0).toUpperCase() + str.substr(1).toLowerCase())
  })
})

//SHOW PASSWORD
$("body").on("click", '[data-action="show-password"]', function () {
  const input = $("#" + $(this).data("focus"))
  if (input.attr("type") === "password") {
    input.attr("type", "text")
    $(this).text($(this).data("textHide"))
  } else {
    input.attr("type", "password")
    $(this).text($(this).data("textShow"))
  }
})
// HIDE ALLERT
$("body").on("click", '.alert', function () {
  if($(this).css('display') ==='block'){
    $(this).hide()
  }

})
// SEARCHING BAR

$("body").on("click", '[data-trigger="show-search-bar"]', function () {
  const form = $(".js-toggle-searching-bar")
  const links = $(".js-category-header-links")
  if($(window).width()>991){
    links.attr('style', 'display:none ')
  }
  form.show()
  $(this).hide()
})

$("body").on("click", '[data-action="hide-search-bar"]', () => {
  const form = $(".js-toggle-searching-bar")
  const links = $(".js-category-header-links")
  form.hide()
  if($(window).width()>991){
    links.attr('style', 'display:flex ')  }

  $('span[data-trigger="show-search-bar"]').show()
})

//END SEARCHING BAR

//
$(document).mouseup((e) => {
  var container = $(".shopping-cart-box")

  // if the target of the click isn't the container nor a descendant of the container
  if (!container.is(e.target) && container.has(e.target).length === 0)
  {
    container.hide()
  }
})
//


// FOCUS INPUT FORM LABEL
$(".input-control")
  .focus(function () {
    $('label[for="' + this.id + '"]').addClass("label-focus")
  })
  .blur(function () {
    $('label[for="' + this.id + '"]').removeClass("label-focus")
  })

// ICON USER

$("body").on("click", '[data-trigger="show-customer-action"]', () => {
  const top = $(".navbar__top").outerHeight()
  $(".customer-action").css("top", top + "px")
  $(".customer-action").fadeToggle("fast")
})

// modal contact

$("#contactModal").on("show.bs.modal", function (event) {
  var button = $(event.relatedTarget) // Button that triggered the modal
  var data = button.data() // Extract info from data-* attributes
  var modal = $(this)
  modal.find(".modal-title").text("Zapytanie o " + data.name)
  modal.find(".modal-body input").val()
})

