

class NavbarManager{
    constructor() {
        this.lastScroll = 0
        this.loaded = false
        this.navbarTop = $('.navbar__top')
        this.topBarHeight = this.navbarTop.outerHeight()
        this.mainHeader = $('.main-header')
    }
    showTopBar(){
        this.mainHeader.css('transform',`translateY(0px)`)

    }
    hiddenTopBar(){

        this.mainHeader.css('transform',`translateY(-${this.topBarHeight}px)`)
    }
    handleScroll(){
        if(window.scrollY < 100) {
            this.showTopBar()
            return
        }
        const dif = window.scrollY - this.lastScroll

        if(dif > 0){
this.hiddenTopBar()
        }
        if(dif < 0){
this.showTopBar()
        }
        this.lastScroll = window.scrollY
    }
    init(){
        document.addEventListener('scroll',(e)=>{
               this.handleScroll()
            })

    }
}




const navbar = new NavbarManager()
navbar.init()



