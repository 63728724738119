
const getWidthBetweenEl = (x, y) => {
    const elX = x.getBoundingClientRect().left
    const elY = y.getBoundingClientRect().right - 8
    return elY - elX - x.getBoundingClientRect().width
}

//  MENUUUUUUUUUUUUUUUUUU

let currentEl = null
let parent = null
let label = null
let id = null
let findedCurrentSubMenu = null
let findedRightMenu = null
let $target = null


$("body").on("mouseenter", ".sub-menu-item", function (e) {

    if (window.innerWidth < 991) {
        return
    }
    parent = $(this).data("parentId")
    label = $(this).data("category")
    id = $(this).data("identifier")
    const submenu = $(`[data-submenu="${parent}"]`)
    findedRightMenu = $('div[data-parent-id="' + parent + '"]')
    findedCurrentSubMenu = $('div[data-parent="' + label + '"]')
    // console.log("finded:", findedRightMenu)
    // console.log("finneded list ", findedCurrentSubMenu)
    // console.log("parent:", parent)
    // console.log("label:" + label)
    // console.log("id:" + id)
    // console.log($(this))
    const getWidth = getWidthBetweenEl(submenu[0], $(".container")[0])
    findedCurrentSubMenu.css({ display: "block" })
    if (findedCurrentSubMenu.length != 0) {
        findedCurrentSubMenu.css({
            width: `${getWidth}px`,
            minHeight:submenu.outerHeight()
        })

        if(submenu.outerHeight() < $(findedCurrentSubMenu.children()[1]).outerHeight()){
            $(findedCurrentSubMenu.children()[1]).addClass('shadow-box')
        }
        // 'height':submenu.outerHeight()+'px'
    } else {
        findedCurrentSubMenu.css({ width: `0px` })
    }
})
$("body").on("mouseleave", ".sub-menu-item", function (e) {
    if (window.innerWidth < 991) {
        return
    }
    $(this).children("a").removeClass("current")

    parent = $(this).data("parentId")
    label = $(this).data("category")
    id = $(this).data("identifier")
    findedRightMenu = $('div[data-parent-id="' + parent + '"]')
    findedCurrentSubMenu = $('div[data-parent="' + label + '"]')
    const $target = null


    findedCurrentSubMenu.css({ display: "none" })
})

//  navigation  mobile

$(".open-sub-menu").on("click", function () {
    if (window.innerWidth < 991) {
        return
    }

    const target = $(this).data("target")
    const targetEL = $(target)
    targetEL.hasClass("open") ? targetEL.hide() : targetEL.show()
    targetEL.toggleClass("open")
    $(this).prev().toggleClass("active")
})


$(".open-sub-menu").on("click", function () {
    if (window.innerWidth < 991) {
        return
    }

    const target = $(this).data("target")
    const targetEL = $(target)
    targetEL.hasClass("open") ? targetEL.hide() : targetEL.show()
    targetEL.toggleClass("open")
    $(this).prev().toggleClass("active")
})
