import Select from "./select"

export class FiltersManager {
  constructor() {
    this.filters = $(".custom-filter")
    console.log("fm")
    this.init()
    this.match = ""
    this.isChange = false
    this.filtersArray = []
    this.resetRootUrl()
    this.selects = []
  }

  getParams(param, url) {
    var urlLink = new URL(url)
    var c = urlLink.searchParams.get(param)
    return c
  }

  resetRootUrl() {
    this.url = prestashop.urls.current_url.split("?")[0]
  }
  setChangeForSelect() {
    this.createParam(this)
  }
  setBlur(that) {
    this.filters.each(function (idx) {
      // if($(this).data().filter =='m'){
      //    return
      // }
      $(this).change(() => {
        const f = this
        // console.log(this,'tetet')
        that.createParam(f)
      })
    })
  }
  checkOtherFilter(that) {
    this.filters.each(function (idx) {
      that.checkFiltersArray(
        $(this).data().filter,
        $(this).val(),
        $(this).data().option
      )
    })
  }
  createParam(trigger) {
    this.isChange = false
    const $this = $(trigger)
    const val = $this.val()

    this.checkOtherFilter(this)

    const filter = $this.data().filter
    const option = $this.data().option
    let inArray = false

    this.getUrl()
  }
  checkFiltersArray(filter, val, option) {
    let inArray = false
    this.filtersArray.forEach((item) => {
      if (item.filter === filter) {
        item.val = val
        inArray = true
        this.isChange = true
        return
      }
    })
    if (!inArray) {
      this.filtersArray.push({ filter, val, option })
    }
  }
  init() {
    this.setBlur(this)
  }

  getUrl() {
    this.resetRootUrl()
    this.match = ""
    const query = []
    this.filtersArray.forEach((e) => {
      switch (e.option) {
        case "one": {
          this.match += e.filter + "=" + e.val + "&"
          break
        }
        case "array": {
          if (e.val.length > 0) {
            query.push(e.filter + ":" + e.val.join(","))
          }
          break
        }
        case "between": {
          let price = "p:"
          const $priceDown = $('input[data-range="down"]')
          const $priceTop = $('input[data-range="up"]')

          if ($priceTop.val() || $priceDown.val()) {
            price += `${$priceDown.val()}-${$priceTop.val()}`
            query.push(price)
          }
        }
      }
    })

    if (query.join("|").length > 1) {
      if (this.getParams("q", prestashop.urls.current_url)) {
        const tempQ = this.getParams("q", prestashop.urls.current_url)
        if (tempQ.includes("promocje")) {
          query.push("promocje")
        }
        if (tempQ.includes("polecane")) {
          query.push("polecane")
        }
        this.match = "q=" + query.join("|") + "&" + this.match
      } else {
        this.match = "q=" + query.join("|") + "&" + this.match
      }
    }
    if (this.getParams("s", prestashop.urls.current_url)) {
      this.match =
        this.match + "s=" + this.getParams("s", prestashop.urls.current_url)
    } else {
      this.match = this.match.slice(0, -1)
    }

    if (this.isChange) {
      window.location = `${this.url}?${this.match}`
    }
  }
}
