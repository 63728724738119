$("body").on("click", '[data-trigger="show-customer-action"]', function(){
    const all = $('.customer-action')
    // console.log(all)
    // all.hide()
    $this = $(this)
    $this.toggleClass('reverse')

    const top = $(".header-bottom").outerHeight()
    $('#' + $this.data('target')).css("top",top+"px")
    $('#' + $this.data('target')).fadeToggle("fast")
})
$("body").on("click", '[data-trigger="show-customer-action-no-reverse"]', function(){
    const all = $('.customer-action')
    // console.log(all)
    // all.hide()
    $this = $(this)
    // $this.toggleClass('reverse')

    const top = $(".header-bottom").outerHeight()
    $('#' + $this.data('target')).css("top",top+"px")
    $('#' + $this.data('target')).fadeToggle("fast")
})



$("body").on("click", '[data-trigger="show-sub-menu-tree"]', function(){
    $this = $(this)

    if ($('#' + $this.data('target')).is(':visible')) {
    }
    $this.toggleClass('reverse')

    $('#' + $this.data('target')).fadeToggle("fast")
})


$("body").on("click", '[data-trigger="show-mobile-menu"]', function(){
    $this = $(this)

    if($this.data('toggle') === 'open'){
        $this.data('toggle','close')
        $('#' + $this.data('target')).css("transform","translateX(-100%)")
    }else{
        $('#' + $this.data('target')).css("transform","translateX(0)")
        $this.data('toggle','open')
    }
})


$("body").on("click", '[data-trigger="show-search-bar"]', () => {
    const form = $('.search-form')
    // console.log(all)
    // all.hide()
    if( $(form).css('display') == 'none'){
        $(form).css('display','flex')
        $('.icon__searching').hide()
    }else{
        $(form).css('display','none')
    }
})

