const updateCartDetails = () => {
  $(".badge-shopping-cart").text(prestashop.cart.products_count)
  $(".price-cart").text(prestashop.cart.totals.total.value)
}

export const generateShoppingCart = () => {
  const cartItems = prestashop.cart.products
  const lists = $(".shopping-cart-list")
  let list = $(lists[0])
    console.log(lists)
  console.log(lists[0])
  console.log(lists[1])
  console.log($('#shopping-cart-top'))

  if(window.innerWidth <=990) {
  list = $(lists[1])

  }
    list.empty()
  cartItems.forEach((e) => {
    let item = $("<li> </li>")
    item.addClass("shopping-cart-item")
    item.data("idProduct", e.id)

    const cover = $("<div> </div>")
    cover.addClass("shopping-cart-item__cover")
    if (e.cover) {
      cover.append('<img src="' + e.cover.small.url + '">')
    } else {
      cover.append(
        '<img src="' + prestashop.urls.no_picture_image.small.url + '">'
      )
    }
    item.append(cover)
    const title = $("<div></div>").addClass("shopping-cart-item__title letter")
    title.text(e.name)
    const price = $("<div></div>").addClass("shopping-cart-item__price")

    const qty = $("<span></span>")
      .addClass("quantity")
      .text(e.quantity + " x ")
    const spanPrice = $("<span class=></span>").addClass("price").text(e.price)
    price.append(qty)
    price.append(spanPrice)
    title.append(price)
    item.append(title)

    // const remove = $('<div>')
    const remove = $("<div></div>").addClass("shopping-cart-item__delete")
    const removeIcon = $("<span></span>").addClass("icon__close")
    removeIcon
      .data("qty", e.quantity)
      .data("cartAction", "remove-item")
      .data("idProduct", e.id)

    removeIcon.on("click", function () {
      const $this = $(this)
      $.ajax({
        url: prestashop.urls.pages.cart,
        type: "POST",
        data: {
          token: prestashop.static_token,
          id_product: e.id,
          delete: 1,
          qty: e.quantity,
          action: "update",
          ajax: true,
          id_customization: 0,
        },
        success: function (response) {
          $(".shopping-cart-box").fadeToggle("fast")

          console.log(prestashop.cart)
          response = JSON.parse(response)
          console.log(response)

          // prestashop.emit('updateCart', {
          //     reason: {}, resp: response
          // })
          prestashop.cart = response.cart
          generateShoppingCart()

          console.log("done")
          console.log(prestashop.cart)
        },
        error: () => {
          console.log("error")
        },
      })
    })
    remove.append(removeIcon)
    item.append(remove)
    list.append(item)
    // $('badge-shopping-cart').text(prestashop.products_count)
  })
  updateCartDetails()

  //     const template = 'li class="shopping-cart-item" data-id-product="product-{$product.id}">
  //     <div class="shopping-cart-item__cover">
  //     <img src="{$product.cover.small.url}" alt="">
  //     </div>
  //     <div class="shopping-cart-item__title">{$product.name}
  //     <div class="shopping-cart-item__price">
  //     <span class="quantity"> {$product.quantity} x</span> <span class="price">{$product.price}</span>
  // </div>
  // </div>
  // <div class="shopping-cart-item__delete"> <span  class="icon__close remove-item-from-cart" data-qty="{$product.quantity}" data-cart-action="remove-item"  data-id-product="{$product.id_product}"></span>   </div>
  // </li>'
}
