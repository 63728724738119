
(function($) {
    $.fn.spinner = function() {
        this.each(function() {
            var el = $(this)

// add elements
            el.wrap('<span class="spinner"></span>')
            el.before('<span class="sub icon__minus"></span>')
            el.after('<span class="add icon__plus"></span>')

// substract
            el.parent().on('click', '.sub', () => {
                console.log('sub')
                if (el.val() > parseInt(el.attr('min')))
                    el.val( (i, oldval) => { return --oldval })
            })

// increment
            el.parent().on('click', '.add', () => {
                if (el.val() < parseInt(el.attr('max')))
                    el.val( (i, oldval) => { return ++oldval })
            })
        })
    }
})(jQuery)

// $('input[data-trigger="counter"]').spinner()