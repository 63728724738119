

$('.shoppingCart').on('click',()=>{
    const top = $('.navbar__top').outerHeight()
if(window.innerWidth > 1219){
    $(".shopping-cart-box").css('top', top+'px')
}else{
    $(".shopping-cart-box").css('top', '71px')

}

    $(".shopping-cart-box").fadeToggle("fast")



})


